import React, { useEffect, useState } from 'react';

import './Skills.css';
import Skill from './components/Skill';
import skillsData from './data/skills.json';

function Skills() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    setSkills(skillsData);
  }, []);

  return (
    <section id='skills'>
      <h1 className='brackets'>Skills</h1>
      <div>
        <div className='skills-grid'>
          {skills.map((skill, index) => (
            <Skill key={index} name={skill.name} icon={skill.icon} description={skill.description} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
