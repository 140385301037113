function CanvasResize() {
    const bgElement = document.getElementById("bg");
    const avatarBars = document.getElementById("avatar-bars");
    const scrollThreshold = 600;

    Resize();

    function GetNewSizeInPX() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
        const limitedWindowWidth = windowWidth / 2;
        const limitedWindowHeight = limitedWindowWidth;

        const initialWidth = limitedWindowWidth;
        const finalWidth = 100; // in px
        const initialHeight = limitedWindowHeight;
        const finalHeight = 100; // in px

        const width = initialWidth - (initialWidth - finalWidth) * (scrollPosition / scrollThreshold);
        const height = initialHeight - (initialHeight - finalHeight) * (scrollPosition / scrollThreshold);

        return {
            x: width,
            y: height,
        };
    }

    function Resize() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        if (scrollPosition < scrollThreshold) {
            const newSize = GetNewSizeInPX();
            const width = newSize.x;
            const height = newSize.y;

            const percentage = (1 - (scrollPosition / scrollThreshold)) * 50;
            const padding = (1 - (scrollPosition / scrollThreshold)) * 40;

            if (width < 768) bgElement.style.left = `-30px`;
            if (width < 768) avatarBars.style.left = `-30px`;
            if (width < 300) bgElement.style.left = `-15px`;
            if (width < 300) avatarBars.style.left = `-25px`;
            if (width < 200) avatarBars.style.left = `-15px`;

            bgElement.style.width = `${width}px`;
            bgElement.style.height = `${height}px`;
            bgElement.style.top = `calc(${percentage}% - ${parseFloat(bgElement.style.height) / 2}px)`;

            avatarBars.style.width = `${width}px`;
            avatarBars.style.height = `${height + 50}px`;
            avatarBars.style.top = `calc(${percentage}% - ${parseFloat(avatarBars.style.height) / 2}px)`;
            avatarBars.style.padding = ``;
        } else {
            bgElement.style.width = `100px`;
            bgElement.style.height = `100px`;
            bgElement.style.top = `0`;
            bgElement.style.left = `0`;

            avatarBars.style.width = `100px`;
            avatarBars.style.height = `100px`;
            avatarBars.style.top = `0`;
            avatarBars.style.left = `0`;
            avatarBars.style.padding = `0`;
        }
    }

    window.addEventListener('resize', Resize);
    window.addEventListener('scroll', Resize);
}

export default CanvasResize;