import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";

import './Socials.css';

import Social from './components/Social';

import GithubIcon from './assets/images/socials/Github.png';
import YoutubeIcon from './assets/images/socials/Youtube.png';
import InstagramIcon from './assets/images/socials/Instagram.png';
import TikTokIcon from './assets/images/socials/Tiktok.png';
import DiscordIcon from './assets/images/socials/Discord.png';
import SteamIcon from './assets/images/socials/Steam.png';
import UsemeIcon from './assets/images/socials/Useme.png';
import XIcon from './assets/images/socials/X.png';

function Socials() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
      if (isInView) {
          mainControls.start("visible");
      }
  }, [isInView]);

  return (
    <section id='socials'>
      <h1 className='brackets'>Socials</h1>
      <motion.div ref={ref}
            variants={{
                hidden: { opacity: 0, y: 50, zIndex: 0 },
                visible: { opacity: 1, y: 0, zIndex: 0 },
            }
            }
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0 }}
        >
        <div id='grid'>
          <Social icon={GithubIcon} link={"https://github.com/xsiadron"} animateDelay={0} />
          <Social icon={YoutubeIcon} link={"https://www.youtube.com/@xsiadron"} animateDelay={0.05} />
          <Social icon={InstagramIcon} link={"https://www.instagram.com/xsiadron/"} animateDelay={0.1} />
          <Social icon={TikTokIcon} link={"https://www.tiktok.com/@xsiadron"} animateDelay={0.15} />
          <Social icon={XIcon} link={"https://twitter.com/xsiadron"} animateDelay={0.2} />
          <Social icon={DiscordIcon} link={""} animateDelay={0.25} />
          <Social icon={SteamIcon} link={"https://steamcommunity.com/id/xsiadron/"} animateDelay={0.3} />
          <Social icon={UsemeIcon} link={"https://useme.com/pl/roles/contractor/xsiadron,329628/"} animateDelay={0.35} />
        </div>
      </motion.div>
    </section>
  );
}

export default Socials;
