import React, { useEffect, useState } from 'react';

import SpecialEvents from './scripts/SpecialEvents';

import './App.css';
import './Custom.css';

import Navigation from './Navigation';
import About from './About';
import Skills from './Skills';
import Projects from './Projects';
import Socials from './Socials';
import Footer from './Footer';
// import Timer from './components/Timer';

import Models from './scripts/3DModels';
import CanvasResize from './scripts/CanvasResize';

import AvatarBars from "./assets/images/AvatarBars.png";

function App() {
  const [canBeRevealed, setCanBeRevealed] = useState(true);

  const handleReveal = () => {
    setCanBeRevealed(true);
  };

  useEffect(() => {
    if (canBeRevealed) {
      CanvasResize();
      Models();
    }
  }, [canBeRevealed]);

  if (!canBeRevealed) {
    // return <Timer onReveal={handleReveal} />;
  }

  return (
    <div className="App">
      <Navigation />
      <main>
        <About />
        <Skills />
        <Projects />
        <Socials />

        <canvas id="bg"></canvas>
        <img src={AvatarBars} id='avatar-bars' />

        <Footer />
      </main>
      <SpecialEvents />
    </div>
  );
}

export default App;
