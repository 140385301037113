import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";

import './Box.css';

import default_image from '../assets/icons/image.png';
import default_background from '../assets/images/default_background.png';

import arrow_external from '../assets/icons/arrow_external.png';
import lock from '../assets/icons/lock.png';
import users from '../assets/icons/users.png';
import calendar from '../assets/icons/calendar.png';

function Box({ title = "Title", description = "Description...", author = "xsiadron", date = "Unknown release date", link, image = default_image, backgroundImage = default_background }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView, mainControls]);

    const imageStyle = {
        backgroundImage: `url(${backgroundImage})`,
    };

    return (
        <motion.div ref={ref} className='box'
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.6, delay: 0 }}
        >
            <a href={link} target='_blank'>
                <div className='background_image' style={imageStyle}><img src={image} /></div>
            </a>
            <motion.div className='box_text'
                variants={{
                    hidden: {y: 50},
                    visible: {y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.4, delay: 0 }}
            >
                <a href={link} target='_blank' className='box_title'>
                    <h2>
                        {title}
                        {link == null ? (
                            <img src={lock} width={20} height={20} alt='lock' />

                        ) : (
                            <img src={arrow_external} width={20} height={20} alt='arrow_external' />
                        )}
                    </h2>
                </a>
                {/* Prevent Google from indexing the description */}
                {/*googleoff: index*/}
                <p data-nosnippet>{description}</p>
                {/*googleon: index*/}
                <div>
                    <img src={users} alt='users' />
                    <small>{author}</small>
                </div>
                <div>
                    <img src={calendar} alt='calendar' />
                    <small>{date}</small>
                </div>
            </motion.div>
        </motion.div>
    );
}

export default Box;
