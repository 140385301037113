import React, { useEffect } from 'react';

function SpecialEvents() {
    useEffect(() => {
        const gameButton = document.getElementById("game-button");
        const avatarBars = document.getElementById("avatar-bars");

        gameButton.addEventListener("dblclick", () => {
            if (avatarBars.style.opacity == 1) {
                avatarBars.style.opacity = 0;
                avatarBars.style.transform = "translateY(20px);";
            } else {
                avatarBars.style.opacity = 1;
                avatarBars.style.transform = "translateY(0px);";
            }
        });

        const showSkillsButtons = document.querySelectorAll(".show-skills-button");

        showSkillsButtons.forEach(button => {
            const allSubSkills = button.parentNode.parentNode.parentNode.querySelector(".sub-skills");
            allSubSkills.style.display = "none"
            button.addEventListener("click", () => {
                const subSkills = button.parentNode.parentNode.parentNode.querySelector(".sub-skills");

                if (subSkills.style.display == "none") {
                    subSkills.style.display = "flex"
                    button.innerHTML = "-"
                } else {
                    subSkills.style.display = "none"
                    button.innerHTML = "+"
                }
            });
        });

        const sectionIds = ['skills', 'projects', 'socials'];
        const navElements = [document.getElementById("about-link"), document.getElementById("projects-link"), document.getElementById("socials-link")];

        const aboutSection = document.getElementById('about');
        window.onscroll = function() {
            const offsetTop = aboutSection.getBoundingClientRect().top + window.scrollY;
            if (window.scrollY < offsetTop*2) {
                if (navElements[0]) navElements[0].classList.add('hover');
            }
        };

        // Create Intersection Observer for each section
        sectionIds.forEach(sectionId => {
            const targetSection = document.getElementById(sectionId);
            const h1TargetSection = targetSection.querySelector(".brackets");

            if (targetSection) {
                const options = {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0,
                };

                const handleIntersection = (entries, observer) => {
                    entries.forEach(entry => {
                        const navElement = navElements[sectionIds.indexOf(sectionId)];
                        
                        if (entry.isIntersecting) {
                            if (h1TargetSection) h1TargetSection.classList.add('hover');
                            if (navElement) navElement.classList.add('hover');
                        } else {
                            if (h1TargetSection) h1TargetSection.classList.remove('hover');
                            if (navElement) navElement.classList.remove('hover');
                        }

                        const offsetTop = aboutSection.getBoundingClientRect().top + window.scrollY;
                        if (window.scrollY < offsetTop*2) {
                            if (navElements[0]) navElements[0].classList.add('hover');
                        }
                    });
                };

                const observer = new IntersectionObserver(handleIntersection, options);

                observer.observe(targetSection);
            }
        });
    }, []);

    return ("");
}

export default SpecialEvents;