import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";
import './Skill.css';

function Skill({ name = "skillname", icon = "", description = "description" }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const mainControls = useAnimation();
    const [transitionStyle, setTransitionStyle] = useState({});

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    const handleAnimationComplete = () => {
        setTransitionStyle({ transition: 'all 0.1s linear' });
    };

    let iconSrc = "";
    try {
        iconSrc = require(`../assets/icons/${icon}`);
    } catch (error) {
        console.error(`Image not found for skill: ${name}`);
    }

    return (
        <motion.div
            ref={ref}
            className='skill'
            style={transitionStyle}
            variants={{
                hidden: { opacity: 0, y: 75, scale: 1.2 },
                visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.2, delay: 0 }}
            onAnimationComplete={handleAnimationComplete}
        >
            {iconSrc && <img src={iconSrc} alt={name} width={40} />}
            {/* Prevent Google from indexing the description */}
            {/*googleoff: index*/}
            <h1>{name}</h1>
            <p dangerouslySetInnerHTML={{ __html: description }} />
            {/*googleon: index*/}
        </motion.div>
    );
}

export default Skill;
