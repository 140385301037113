import React, { useEffect } from 'react';

import './Footer.css';

function Footer() {
  useEffect(() => {
  }, []);

  return (
    <footer>
      <p>lukasz.frydrych@xsiadron.com</p>
      <p>Copyright &copy; 2024. Created by Luke `xsiadron` Frydrych</p>
    </footer>
  );
}

export default Footer;
