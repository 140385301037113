import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";

import './Social.css';

import arrow_external from '../assets/icons/arrow_external.png';

function Social({ icon, link, animateDelay }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <motion.div ref={ref}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
            }
            }
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: animateDelay }}
        >
            <a className='social' target='_blank' href={link}>
                <img src={icon} />
                <p>xsiadron</p>
            </a>
        </motion.div>
    );
}

export default Social;
